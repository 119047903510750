@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .page {
    @apply flex flex-col h-screen overflow-hidden bg-dark-700 relative;
  }
  body {
    @apply px-safe;
  }

  .main {
    @apply flex-1 scrollbar-hide pb-10 bg-dark-700 overflow-y-scroll overflow-x-hidden relative;
  }
  .fadeIn {
    @apply transition duration-500 ease-in-out opacity-0 h-0 overflow-hidden;
  }
  .fadeInActive {
    @apply opacity-100;
  }
  .avatar-placeholder {
    @apply bg-[url('/images/avatar-placeholder.png')] bg-center bg-[percentage:130%_130%] bg-no-repeat bg-dark-700;
  }
}
@layer utilities {
  .clip-profile-bg {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 13%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 13%, 0% 100%);
  }
}

img {
  pointer-events: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.text-shadow-dark {
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
.img-box-shadow {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -0.81666em;
  margin-left: -1.5em;
}

.video-js button {
  outline: none;
}
.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

.vjs-poster {
  background-color: #161616;
}

.video-js .vjs-big-play-button {
  /* play button*/
  font-size: 2.5em;
  line-height: 2.3em;
  height: 2.5em;
  width: 2.5em;
  font-size: 2.3em;
  line-height: 2.1em;
  height: 2.3em;
  width: 2.3em;
  border-radius: 2.5em;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  border-width: 0.1em;
  margin-top: -1.25em;
  margin-left: -1.75em;
  transform: translate(-50%, -50%);
  margin-top: 0;
  margin-left: 0;
}
/* play icon */
.vjs-big-play-button .vjs-icon-placeholder {
  font-size: 1.4em;
}
/* loading */
.vjs-loading-spinner {
  font-size: 2.5em;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  margin-top: -1em;
  margin-left: -1.5em;
}
